import * as React from 'react'

function SvgIcd10Classification(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fillRule='evenodd'
      clipRule='evenodd'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <defs>
        <style>
          {'.icd10-classification_svg__fil0{fill:#000;fill-rule:nonzero}'}
        </style>
      </defs>
      <path
        className='icd10-classification_svg__fil0'
        d='M17.959 15.994H5.816V11.65H18.184V15.994h-.225zm-11.693-.45h11.468V12.1H6.266v3.443z'
      />
      <path
        className='icd10-classification_svg__fil0'
        d='M7.481 3.206h7.127c.588 0 1.122.24 1.509.627.387.387.627.921.627 1.509V12.1H7.256V3.206h.225zm7.127.45H7.706v7.995h8.588v-6.31c0-.463-.19-.884-.496-1.19a1.68 1.68 0 00-1.19-.495z'
      />
      <path
        className='icd10-classification_svg__fil0'
        d='M8.846 5.94h6.308v.45H8.846zM8.846 7.522h6.308v.45H8.846zM9.032 12.654h.723v2.335h-.723v-2.335zm2.795 1.381l.633.191c-.043.178-.11.325-.2.445a.869.869 0 01-.341.269c-.136.06-.308.09-.517.09-.254 0-.461-.037-.622-.11a.988.988 0 01-.417-.39c-.116-.186-.175-.423-.175-.713 0-.387.103-.684.308-.891.206-.208.497-.312.873-.312.294 0 .525.06.693.179.169.119.294.301.375.548l-.637.142a.55.55 0 00-.07-.157.42.42 0 00-.344-.172.424.424 0 00-.376.198c-.066.097-.099.25-.099.46 0 .26.04.437.118.533.08.096.19.144.331.144.138 0 .243-.039.314-.116a.718.718 0 00.153-.338zm.993-1.381h1.072c.21 0 .381.029.512.086.13.058.238.14.322.247.085.107.147.232.185.374.038.143.057.293.057.453 0 .25-.028.443-.085.58a.948.948 0 01-.237.346.77.77 0 01-.324.186 1.677 1.677 0 01-.43.063H12.82v-2.335zm.721.529v1.275h.177c.151 0 .258-.016.322-.05a.342.342 0 00.15-.175c.036-.083.054-.218.054-.405 0-.248-.04-.417-.121-.508-.081-.091-.215-.137-.402-.137h-.18zM8.846 9.104h6.308v.45H8.846z'
      />
    </svg>
  )
}

export default SvgIcd10Classification
